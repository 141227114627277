import $ from "jquery"

$(function() {
    /*
    const $menubutton = $("#menubutton");
    const $desktopmenu = $("#desktopmenu");
    const $body = $("body");
    $menubutton.on("click", function() {
        const newvisible = !$desktopmenu.hasClass("visible");
        $desktopmenu.toggleClass("visible", newvisible);
        $body.toggleClass("desktopmenuvisible", newvisible);
    });
    */

    const $desktopmenu = $("#desktopmenu");
    const $ul = $desktopmenu.find("ul.dropdownmenu").first();
    $ul.children("li").slice(-1).find("ul").first().addClass("opens-right"); //slice -1 = last 1. slice -2 = last 2

    //disallow click on menuitems with submenu
    $desktopmenu.on("click", "li.hassubmenu > a", function(ev) {
        ev.preventDefault();
    });

    /*
    const $submenuspacer = $desktopmenu.find(".submenuspacer");
    $(document).on("dropdownmenu:show", function(ev, params) {
        const $submenu = params.$submenu;
        const submenuHeight = $submenu.outerHeight();
        $submenuspacer.height(submenuHeight);

        $desktopmenu.addClass("submenuopen");
    });
    $(document).on("dropdownmenu:hideall", function(ev, params) {
        $submenuspacer.height(0);

        $desktopmenu.removeClass("submenuopen");
    });
    */

    function enhanceHtml() {
        $ul.find("li.hassubmenu").append('<svg class="icon icon-arrowdown"><use xlink:href="#icon-arrownav"></use></svg>');
    }
    enhanceHtml();
});