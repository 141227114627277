import $ from "jquery";
import Isotope from "isotope-layout";
import 'isotope-packery';
// npm install isotope-layout
// npm install isotope-packery

$(function() {
    $(".bl-impressionen").each(function() {
        const $bl = $(this);

        //Init isotope
        const $guttersizer = $bl.find(".guttersizer");
        const $columnsizer = $bl.find(".columnsizer");

        const iso = new Isotope(".bl-impressionen .ctrimages", {
            layoutMode: 'packery',
            itemSelector: ".image",
            percentPosition: true,
            packery: {
                gutter: $guttersizer.get(0),
                columnWidth: $columnsizer.get(0)
            }
        });

        $(window).on("load", function() {
            iso.layout();
        });

        //Load more button
        const $btnloadmore = $bl.find(".btnloadmore");
        $btnloadmore.on("click", function() {
            $bl.find(".image.hideonstart").show();
            iso.layout();
            $btnloadmore.hide();
        });
    });
});