//npm install swiper
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

// npm install jquery.scrollto
import "jquery.scrollto";

import $ from "jquery";

$(function() {
    $(".bl-heroslider").each(function() {
        const $bl = $(this);

        initSwiper($bl);
        initScrollDownArrow($bl);
    });
});

function initSwiper($bl) {
    const swiper = new Swiper($bl.find(".swiper").get(0), {
        //loop: true,
        autoHeight: false,
        
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
      
        //speed: 1500,
        //autoplay: {
        //  delay: 4000
        //}
  
    });    
}

function initScrollDownArrow($bl) {
    const $scrolldownarrow = $bl.find(".ctrscrolldownarrow");
    $scrolldownarrow.on("click", function() {
        const $nextElement = $bl.next();
        const headerHeight = $("header").outerHeight();
        $(window).scrollTo($nextElement, 500, {
            offset: -headerHeight-50
        }); 
    });
}