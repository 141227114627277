import $ from "jquery";
import Swiper from 'swiper/bundle';

$(function() {
    $(".bl-aktuelllist").each(function() {
        const $bl = $(this);

        const swiper = new Swiper($bl.find(".swiper").get(0), {
            //loop: true,
            //autoHeight: true,
          
            navigation: {
              nextEl: $bl.find(".custom-button-next").get(0),
              prevEl: $bl.find(".custom-button-prev").get(0),
            },
      
            /*
            speed: 1500,
            autoplay: {
              delay: 4000
            }
            */

            slidesPerView:1,
            spaceBetween:10,
            breakpoints: {
                740: {
                  slidesPerView: 2,
                  spaceBetween: 15
                },
                1024: { //>= 1024px
                  slidesPerView: 3,
                  spaceBetween: 20
                }
            }
        });
    });
});
