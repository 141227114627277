import $ from "jquery";
import "jquery.scrollto";
// npm install jquery.scrollto

function getHeaderHeight() {
    return $("header").outerHeight();
}

function findTarget(scrolltarget) {
    let $scrolltarget = $("[data-scrolltarget="+scrolltarget+"]");
    if($scrolltarget.length > 0) {
        return $scrolltarget;
    }

    $scrolltarget = $("a[name="+scrolltarget+"]");
    if($scrolltarget.length > 0) {
        return $scrolltarget;
    }

    $scrolltarget = $("#"+scrolltarget);
    if($scrolltarget.length > 0) {
        return $scrolltarget;
    }

    return null;
}

function scrollToTarget(scrolltarget) {
    const $scrolltarget = findTarget(scrolltarget);
    if($scrolltarget) {
        //const scrollOffset = -getHeaderHeight();
        const scrollOffset = -0.1*window.innerHeight;
        $(window).scrollTo($scrolltarget, 1000, {
            offset: scrollOffset
        });

        document.body.dispatchEvent(
            new CustomEvent("custom:scrolltotarget", {
                target:scrolltarget
            })
        );
    }
}

function scrollToHash() {
    const hash = window.location.hash;
    if(hash.length < 2) {
        return;
    }
    const scrolltarget = hash.substring(1);
    scrollToTarget(scrolltarget);
}

$(window).on("load", scrollToHash);
$(window).on("hashchange", scrollToHash);

$(function() {
    $("a[href*='#']").on("click", function() {
        setTimeout(scrollToHash, 100);
    });
});
