import $ from "jquery";

$(function() {
    //Init popup event handlers
    $(".popupmodal .icon-closepopup, .popupmodal .bgoverlay").on("click", function() {
        const $popupmodal = $(this).closest(".popupmodal");
        $popupmodal.fadeOut(200);
    });

    installLinkInterceptor();
});

function loadContent(url) {
    const $popupmodal = $(".popupmodal");
    $popupmodal.find(".popupcontent").remove();
    $popupmodal.find(".popup").load(url+" .popupcontent"); //load only div "popupcontent". rest is ignored.
}

function openPopup(url) {
    if(url) {
        loadContent(url);
    }
    $(".popupmodal").fadeIn(200);
}

function installLinkInterceptor()
{
    $(document).on("click", "a", function(ev) {
        if(!window.resourcesWithPopups) {
            return;
        }
        const $a = $(this);
        const href = $a.attr("href");
        const isPopup = window.resourcesWithPopups.indexOf(href) != -1;
        if(isPopup) {
            ev.preventDefault();
            openPopup(href);
        }
    });
}

//Demo only
//window.openPopup = openPopup;

/*
setTimeout(function() {
    openPopup("https://dev.schaerme-kurhaus-sarnersee.pixmill.ch/testseite");
}, 2000);
*/